import classnames from 'classnames'
import React, { useState } from 'react'
import { useLayoutData } from '@/contexts/layout'
import { NFT_TYPES } from '@/enum'
import { RARITY_SORT } from '@/constants'
import useBreakpoints from '@/hooks/useBreakpoints'
import { Button, Checkbox, MultiToggle } from '@/components'
import InventoryBrowserFilterWrapper from '@/components/InventoryBrowser/InventoryBrowserFilterWrapper'
import Section from '@/components/InventoryBrowser/filter/Section'
import styles from './styles.module.scss'
import { OptionsType } from '@/components/InventoryBrowser'
import CopyButton from '@/components/Button/CopyButton'
import InputSearch from '@/components/Inputs/InputSearch'
import { match } from '@/util/regexHelpers'
import { parseQueryParameter } from '@/util/queryHelpers'
import useUpdateInventoryQuery from '@/hooks/useUpdateInventoryQuery'

interface IInventoryFilter {
  options: OptionsType
  hideListingFilter?: boolean
  hideCardIDFilter?: boolean
  onToggle: () => void
  isExpanded: boolean
  className?: string
  style?: React.CSSProperties
  inventoryLoading: boolean
}

const INVENTORY_FILTER_ITEMS: Record<
  keyof typeof NFT_TYPES,
  { label: string; value: string }
> = Object.entries(NFT_TYPES).reduce((acc, [key, value]) => {
  acc[key.toUpperCase()] = {
    label: value,
    value: value
  }
  return acc
}, {} as any)

const formatCardIDInputValue = (value: string, previous?: string): string => {
  if (!value) return ''

  if (previous && value.length < previous.length) {
    return value
  }

  const next =
    match(value, /(\d+)([,\s]?)/g)
      .map((match): string => {
        const [, digits, separator] = match
        return digits + (separator ? ', ' : '')
      })
      .reduce((accumulator, value) => accumulator + value, '') || ''

  return next
}

const InventoryFilter = ({
  options,
  hideListingFilter,
  hideCardIDFilter,
  onToggle,
  isExpanded,
  className
}: IInventoryFilter) => {
  const [viewAll, setViewAll] = useState(false)
  const { isMobile } = useBreakpoints()
  const { getSectionCollapseState } = useLayoutData()
  const { update: updateQuery, query } = useUpdateInventoryQuery()

  const {
    type,
    show_only_listings,
    show_only_not_listings,
    show_duplicates,
    exclude_lowest_mint,
    owners: qOwners,
    collections: qCollections,
    variants: qVariants,
    rarities: qRarities,
    cardids: qCardIDs
  } = query

  const collections: Array<Number> =
    parseQueryParameter(qCollections).map(Number)
  const rarities = parseQueryParameter(qRarities)
  const owners = parseQueryParameter(qOwners)
  const variants = parseQueryParameter(qVariants)
  const cardids = formatCardIDInputValue(
    parseQueryParameter(qCardIDs).join(',')
  )

  const { collections: collectionOptions, owners: ownerOptions } = options

  let filteredRarities = []
  let filteredVariants = []
  collectionOptions
    .filter(
      ({ drop_id }) => !collections?.length || collections.includes(drop_id)
    )
    .map(({ rarities, variants }) => {
      filteredRarities = filteredRarities.concat(
        rarities.filter(rarity => filteredRarities.indexOf(rarity) < 0)
      )
      filteredVariants = filteredVariants.concat(
        variants.filter(variant => filteredVariants.indexOf(variant) < 0)
      )
    })

  filteredRarities.sort(
    (a, b) => RARITY_SORT.indexOf(a) - RARITY_SORT.indexOf(b)
  )

  const filteredCollections = collectionOptions.slice(
    0,
    viewAll ? collectionOptions.length : 10
  )

  let variantOptions = []

  if (collections.length === 1) {
    variantOptions = collectionOptions.find(
      collection => collection.drop_id === collections[0]
    )?.variants
  }

  const collapsed = !isMobile && !isExpanded
  const shouldShowHideListedFilter = !hideListingFilter && !show_only_listings

  const renderContent = () => {
    return (
      <div className="relative z-10 bg-gray-850 md:bg-gray-900">
        <Section
          title="View"
          icon="filterView"
          defaultOpen={getSectionCollapseState('View')}
          collapse={collapsed}
        >
          <MultiToggle
            items={Object.values(INVENTORY_FILTER_ITEMS)}
            value={type?.toString() || INVENTORY_FILTER_ITEMS.ALL.value}
            onToggle={value =>
              updateQuery({
                type: 'parameter',
                parameter: 'type',
                value: value,
                action: 'apply'
              })
            }
          />
          {shouldShowHideListedFilter && (
            <Checkbox
              className={classnames(styles.selectOption, styles.margin)}
              label="Hide Listings"
              square
              selected={!!show_only_not_listings}
              onChange={() =>
                updateQuery({
                  type: 'parameter',
                  parameter: 'show_only_not_listings',
                  value: `${!show_only_not_listings}`,
                  action: 'apply'
                })
              }
            />
          )}
          <Checkbox
            className={classnames(styles.selectOption, {
              [styles.margin]: !shouldShowHideListedFilter
            })}
            label="Only Show Duplicates"
            square
            selected={!!show_duplicates}
            onChange={() =>
              updateQuery({
                type: 'parameter',
                parameter: 'show_duplicates',
                value: `${!show_duplicates}`,
                action: 'apply'
              })
            }
          />
          {!!show_duplicates && (
            <Checkbox
              className={styles.selectOption}
              label="Exclude Lowest Mint"
              square
              selected={!!exclude_lowest_mint}
              onChange={() =>
                updateQuery({
                  type: 'parameter',
                  parameter: 'exclude_lowest_mint',
                  value: `${!exclude_lowest_mint}`,
                  action: 'apply'
                })
              }
            />
          )}
        </Section>
        {ownerOptions.length > 1 && (
          <Section
            title="Address"
            icon="filterAddress"
            defaultOpen={getSectionCollapseState('Address')}
            collapse={collapsed}
          >
            {ownerOptions.map((owner, index) => (
              <Checkbox
                key={index}
                className={styles.selectOption}
                label={
                  <div className={styles.lblWaxAddress}>
                    {owner}
                    <div onClick={e => e.stopPropagation()}>
                      <CopyButton
                        valueToCopy={owner}
                        successMessage="Address Copied to Clipboard"
                        size="sm"
                        className={styles.btnCopy}
                      />
                    </div>
                  </div>
                }
                square
                selected={owners?.includes(owner)}
                onChange={() =>
                  updateQuery({
                    type: 'parameter',
                    parameter: 'owners',
                    value: owner,
                    action: 'toggle'
                  })
                }
              />
            ))}
          </Section>
        )}
        <Section
          title="Collections"
          icon="filterCollections"
          defaultOpen={getSectionCollapseState('Collections')}
          collapse={collapsed}
        >
          {filteredCollections.map(collection => (
            <Checkbox
              key={collection.drop_id}
              className={styles.selectOption}
              label={collection.drop_name}
              square
              selected={collections?.includes(collection.drop_id)}
              onChange={() =>
                updateQuery({
                  type: 'parameter',
                  parameter: 'collections',
                  value: collection.drop_id.toString(),
                  action: 'toggle'
                })
              }
            />
          ))}
          {collectionOptions.length > 10 && (
            <Button
              className="mt-2"
              theme="clean-blue"
              size="xs"
              onClick={() => setViewAll(!viewAll)}
            >
              {viewAll ? 'View Less' : 'View All'}
            </Button>
          )}
        </Section>
        {type !== NFT_TYPES.UNOPENED && (
          <Section
            title="Rarity"
            icon="filterRarity"
            defaultOpen={getSectionCollapseState('Rarity')}
            collapse={collapsed}
          >
            {filteredRarities.map((rarity, index) => (
              <Checkbox
                key={index}
                className={styles.selectOption}
                label={rarity}
                square
                selected={rarities?.includes(rarity)}
                onChange={() =>
                  updateQuery({
                    type: 'parameter',
                    parameter: 'rarities',
                    value: rarity,
                    action: 'toggle'
                  })
                }
              />
            ))}
          </Section>
        )}
        {!hideCardIDFilter && collections.length === 1 && (
          <Section
            title="Card ID"
            icon="hash"
            defaultOpen={getSectionCollapseState('CardID')}
            collapse={collapsed}
          >
            <InputSearch
              fullWidth
              value={cardids}
              placeholder="1, 15, 30, etc."
              onChange={value => {
                updateQuery({
                  type: 'parameter',
                  parameter: 'cardids',
                  value: value,
                  action: 'apply'
                })
              }}
              filter={formatCardIDInputValue}
            />
            <p className="pt-1 text-sm text-gray-500">
              Separate multiple Card IDs with a comma or space.
            </p>
          </Section>
        )}
        {type !== NFT_TYPES.UNOPENED && !!variantOptions?.length && (
          <Section
            title="Variants"
            icon="filterVariants"
            defaultOpen={getSectionCollapseState('Variants')}
            collapse={collapsed}
          >
            {variantOptions.map(variant => (
              <Checkbox
                key={variant}
                className={styles.selectOption}
                label={variant}
                square
                selected={variants?.includes(variant)}
                onChange={() =>
                  updateQuery({
                    type: 'parameter',
                    parameter: 'variants',
                    value: variant,
                    action: 'toggle'
                  })
                }
              />
            ))}
          </Section>
        )}
      </div>
    )
  }

  return (
    <InventoryBrowserFilterWrapper
      className={className}
      collapsed={collapsed}
      isExpanded={isExpanded}
      onToggle={onToggle}
    >
      {renderContent()}
    </InventoryBrowserFilterWrapper>
  )
}

export default InventoryFilter
