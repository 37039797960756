import { useRouter } from 'next/router'
import React, { useMemo } from 'react'
import { DefaultLayout } from '@/layouts'
import { buildOgImageUrl } from '@/util/cloudinaryHelpers'
import { isValidAddress } from '@/util/accountHelpers'
import { useProfileGetQuery } from '@/api/resources/profile/get'
import ProfileHeader from '@/components/Profile/Header'
import ProfileBrowser from '@/components/PageSpecificComponents/profile/ProfileBrowser'

const ProfilePage = () => {
  const router = useRouter()
  const { query } = router
  const { profile } = query

  const hasValidAddress = isValidAddress(profile)
  // Fetch Profile
  const { data: profileData } = useProfileGetQuery(profile as string, {
    enabled: !!profile && hasValidAddress,
    onSuccess: () => {},
    onError: error => {
      if (error.statusCode === 400 || error.statusCode === 404) {
        if (error.profileRedirect) {
          return router.push(`/${error.profileRedirect}`)
        }
        return router.push('/404')
      }
    }
  })

  const seo = useMemo(() => {
    return {
      og: {
        image: buildOgImageUrl('pages/profile/og')
      },
      title: profileData?.account || 'Profile',
      description:
        'View this Droppp user’s profile to browse their full digital collection and stats.',
      keywords:
        'droppp, profile, avatar, banner, customize, nft, pfp, digital collectibles, collector, nft marketplace'
    }
  }, [profileData])

  if (!!profile && !hasValidAddress) {
    router.push('/404')
    return null
  }

  return (
    <DefaultLayout
      seo={seo}
      mainElementClassName="min-h-[100vh]"
      footerConfiguration={{
        removeDefaultOffset: true,
        hasBorder: true
      }}
      headerConfiguration={{ offset: { default: 0, md: 0 } }}
    >
      <div className="flex-1 flex flex-col">
        {profileData && (
          <>
            <ProfileHeader profile={profileData} />
            <ProfileBrowser profileData={profileData} />
          </>
        )}
      </div>
    </DefaultLayout>
  )
}

export default ProfilePage
