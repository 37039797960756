import React from 'react'
import { useGetAssets } from '@/api/resources/assets/get'
import EmptyList from '@/components/EmptyList'
import InventoryBrowser, { Card } from '@/components/InventoryBrowser'
import BrowserGridItem, {
  BrowserGridItemType
} from '@/components/InventoryBrowser/BrowserGridItem'
import InventoryFilter from '@/components/InventoryBrowser/filter/InventoryFilter'
import ItemDetail, { DetailAssetType } from '@/components/ItemDetail'
import Loading from '@/components/Loading'
import { useRouter } from 'next/router'
import { Profile } from '@/api/resources/profile/get/schema'

const minHeightClass = 'min-h-[calc(100vh-var(--headerHeight))]'

type Props = {
  profileData: Profile
}

export default function ProfileBrowser({ profileData }: Props) {
  const { query } = useRouter()

  // pulling off profile from query - Josh Dobson - 4/28/23
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { profile, ...params } = query

  // Fetch Profile Assets
  const { isLoading, data, hasNextPage, fetchNextPage } = useGetAssets(
    { account: profileData.account.toString(), ...params },
    !!profileData
  )

  if (isLoading)
    return (
      <div className="relative flex-1 min-h-[120px]">
        <Loading />
      </div>
    )

  // @ts-expect-error
  const { owners, collections, total_count, items } = data

  const options = {
    owners,
    collections
  }

  // @ts-expect-error - related to infinite query not passing back type
  const cards: Card<Details>[] = items.map((item, index) => {
    return {
      index: index,
      id: item.id,
      content: (isActive, _, cardMetrics) => (
        <BrowserGridItem
          isSmallLayout={cardMetrics.cardLayout === 'small'}
          key={item.id}
          activeId={item.id}
          asset={item}
          isPublic={true}
          isActive={isActive}
        />
      ),
      details: item
    } as Card<BrowserGridItemType>
  })

  return (
    <div className="relative min-h-[400px] flex-1">
      {total_count ? (
        <InventoryBrowser
          className={minHeightClass}
          options={options}
          detailAssetType={DetailAssetType.publicAsset}
          cards={cards}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          staticElementHeight={0}
          renderEmptyResults={<EmptyList type="noResultsProfile" />}
          renderFilter={(onToggle, isExpanded) => (
            <InventoryFilter
              options={options}
              hideListingFilter
              hideCardIDFilter
              onToggle={onToggle}
              isExpanded={isExpanded}
              className="flex-shrink-0 sticky top-[73px] z-30"
              inventoryLoading={isLoading}
            />
          )}
          details={(details, presentationStyle) => (
            <ItemDetail
              type={DetailAssetType.publicAsset}
              presentationStyle={presentationStyle}
              id={details.id.toString()}
            />
          )}
        />
      ) : (
        <EmptyList
          type={profileData.is_owner ? 'emptyProfile' : 'emptyOtherProfile'}
        />
      )}
    </div>
  )
}
